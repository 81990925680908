import axios from "axios";
import router from "./Router";

let apiConfig = {
    baseURL: process.env.VUE_APP_API_URL,
    //defaultURL: process.env.VUE_APP_SERVER_URL,
    withCredentials: true
};

const Api = axios.create(apiConfig);

Api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    router.push({path: "/error/400"});
                    break;
                case 401: {
                    let startLocation = router["history"]["_startLocation"].split("/")[1];
                    if (
                        router.currentRoute.name !== "login" && router.currentRoute.name !== "registration" && router.currentRoute.name !== "newpassword" && startLocation !== "registration"
                    )
                        router.push({name: "login"});
                    break;
                }
                case 402:
                    router.push({path: "/error/402"});
                    break;
                case 403:
                    router.push({path: "/error/403"});
                    break;
                // case 404:
                // 	router.push({ path: "/error/404" });
                // 	break;
                case 405:
                    router.push({path: "/error/405"});
                    break;
            }
        }
    }
);

export default Api;
