<template>
  <div class="loading-overlay fade" :class="{ show: shown }">
    <div class="overlay"></div>
    <font-awesome-icon icon="fa-duotone fa-spinner" spin size="5x" />
    <span v-if="text">{{ text }}</span>
  </div>
</template>
  
  <script>
import { fadeIn } from "@/mixins/FadeIn";

export default {
  mixins: [fadeIn],
  props: {
    text: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
  
  <style>
</style>
  