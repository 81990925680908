import {SET_COMPANY_SELECTION_LIST, GET_COMPANY_SELECTION_LIST} from './mutations.type';
import Api from '@/Api';

export default {
    namespaced: true,
    state: {
        companySelectionList: [],
    },
    getters: {
        [GET_COMPANY_SELECTION_LIST]: (state) => state.companySelectionList,
    },
    actions: {
        async fetchSelectionList({commit}) {
            try {
                let response = await Api.get('selection-values-company');
                commit(SET_COMPANY_SELECTION_LIST, response.data);
            } catch (error) {
                console.error('Failed to fetch records: ', error);
            }
        },
    },
    mutations: {
        [SET_COMPANY_SELECTION_LIST](state, list) {
            state.companySelectionList = list;
        },
    },
};