import Router from "vue-router";
import store from "@/store";

//import XY from "./routes/XY";

const baseRoutes = [
    {
        path: "/login",
        name: "login",
        component: () => import("./pages/Login"),
    },
    {
        path: "/registration",
        name: "registration",
        component: () => import("./pages/Registration"),
        props: (route) => {
            return {
                token: route.params.token,
                id: route.params.id,
            };
        },
    },
    {
        path: "/",
        name: "home",
        component: () => import("./pages/Startseite"),
    },
    {
        path: "/error/:errorcode",
        name: "errorpage",
        component: () => import("./pages/Errorpage"),
        props: (route) => {
            return {
                errorcode: route.params.errorcode,
            };
        },
    },
    {
        path: "/employees",
        name: "employees",
        component: () => import("./pages/Employees"),
        beforeEnter: (to, from, next) => {
            let user = store.state.user;
            if (user && user.role && (user.role.id === "1889B2E8093" || user.role.id === "188AEA750CF" || user.role.id === "18EEC46A8DB")) {
                //ADMIN, MANUFACTURER, COUNTRY ADMIN
                next();
            } else {
                next("/");
            }
        },
    },
    {
        path: "/admins",
        name: "admins",
        component: () => import("./pages/Admins"),
        beforeEnter: (to, from, next) => {
            let user = store.state.user;
            if (user && user.role && (user.role.id === "1889B2E8093")) {
                //ADMIN
                next();
            } else {
                next("/");
            }
        },
    },
    {
        path: "/companies",
        name: "companies",
        component: () => import("./pages/Companies"),
        beforeEnter: (to, from, next) => {
            let user = store.state.user;
            if (user && user.role && (user.role.id === "1889B2E8093" || user.role.id === "18EEC46A8DB")) {
                //ADMIN, COUNTRY ADMIN
                next();
            } else {
                next("/");
            }
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("./pages/Profile"),
    },
    {
        path: "/company/:id?",
        name: "company",
        component: () => import("./pages/Company"),
        props: (route) => {
            return {
                id: route.params.id,
            };
        },
        beforeEnter: (to, from, next) => {
            let user = store.state.user;
            if (user && user.role && (user.role.id === "1889B2E8093" || user.role.id === "188AEA750CF" || user.role.id === "18EEC46A8DB")) {
                //ADMIN, MANUFACTURER, COUNTRY ADMIN
                next();
            } else {
                next("/");
            }
        },
    },
    {
        path: "/employee/:id?",
        name: "employee",
        component: () => import("./pages/Employee"),
        props: (route) => {
            return {
                id: route.params.id,
            };
        },
        beforeEnter: (to, from, next) => {
            if (store.state.user) {
                let user = store.state.user;
                if (user && user.role && (user.role.id === "1889B2E8093" || user.role.id === "188AEA750CF" || user.role.id === "18EEC46A8DB")) {
                    //ADMIN, MANUFACTURER, COUNTRY ADMIN
                    next();
                } else {
                    next("/");
                }
            } else {
                store.dispatch('getUser').then(() => {
                    let user = store.state.user;
                    if (user && user.role && (user.role.id === "1889B2E8093" || user.role.id === "188AEA750CF" || user.role.id === "18EEC46A8DB")) {
                        //ADMIN, MANUFACTURER, COUNTRY ADMIN
                        next();
                    } else {
                        next("/");
                    }
                });
            }
        },
    },
    {
        path: "/products",
        name: "products",
        component: () => import("./pages/Products"),
		beforeEnter: (to, from, next) => {
			let user = store.state.user;
			if (user && user.role && (user.role.id === "188AEA7CD44" || user.role.id === "188AEA750CF")) {
				//PRODUCT DEVELOPER, MANUFACTURER
				next();
			} else if(user && user.role && user.role.id === "188AEA80E7A"){
				//PRODUCER
				next("/publishedProducts");
			} else {
                next("/");
            }
		},
    },
    {
        path: "/product/:id?",
        name: "product",
        component: () => import("./pages/Product"),
        props: (route) => {
            return {
                id: route.params.id,
            };
        },
        beforeEnter: (to, from, next) => {
            let user = store.state.user;
            if (user && user.role && (user.role.id === "188AEA7CD44" || user.role.id === "188AEA750CF" || user.role.id === "188AEA80E7A")) {
                //PRODUCT DEVELOPER, MANUFACTURER, PRODUCER
                next();
            } else {
                next("/");
            }
        },
    },
    {
        path: "/newpassword",
        name: "newpassword",
        component: () => import("./pages/NewPassword"),
        props: (route) => {
            return {
                token: route.params.token,
            };
        },
    },
    {
        path: "/publishedProducts",
        name: "publishedProducts",
        component: () => import("./pages/PublishedProducts"),
        beforeEnter: (to, from, next) => {
            let user = store.state.user;
            if (user && user.role && (user.role.id === "188AEA7CD44" || user.role.id === "188AEA750CF" || user.role.id === "188AEA80E7A")) {
                //PRODUCT DEVELOPER, MANUFACTURER, PRODUCER
                next();
            } else {
                next("/");
            }
        },

    },
];

//const routes = baseRoutes.concat(XY);
const routes = baseRoutes;

export default new Router({
    mode: "history",
    base: process.env.VUE_APP_BASE_URL,
    routes: routes,
});
