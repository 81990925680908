<template>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    @click="close"
  >
    <span aria-hidden="true">
      <font-awesome-icon icon="fa-light fa-times" />
    </span>
  </button>
</template>

<script>
export default {
  name: "ModalCloseButton",
  methods: {
    close() {
      this.$emit("confirmed");
    },
  },
};
</script>

<style>
</style>
