<template>
  <div
    class="navbar-side-container col-2 p-0 h-100-vh bg-white box-shadow-right w-260-px"
  >
    <nav>
      <ul>
        <li
          v-for="item in navigationItems"
          :key="item.name"
          :class="getItemClasses(item)"
        >
          <span @click="toggleSubMenu(item)">
            <font-awesome-icon :icon="item.icon" class="sidebar-icon" />
            <span class="ml-3">{{ item.name }}</span>
          </span>
          <ul v-if="item.submenu && item.expanded">
            <li
              v-for="subItem in item.submenu"
              :key="subItem.name"
              :class="{ active: isActiveRoute(subItem.route) }"
            >
              <router-link class="w-100-p h-100-p d-flex" :to="subItem.route">
                <span class="ml-3">{{ subItem.name }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Api from "@/Api";

export default {
  name: "NavbarSide",
  data() {
    return {
      user: null,
      navigationItems: [
        // {
        //   name: "Home",
        //   icon: "far fa-fw fa-home",
        //   route: "/",
        //   submenu: null,
        //   expanded: false,
        // },
        // {
        //   name: "Company",
        //   icon: "far fa-fw fa-building",
        //   submenu: [
        //     {
        //       name: "Employees",
        //       route: "/employees",
        //     },
        //     {
        //       name: "Companies",
        //       route: "/companies",
        //     },
        //   ],
        //   expanded: false,
        // },
        // {
        //   name: "Products",
        //   icon: "far fa-fw fa-basket-shopping",
        //   submenu: [
        //     {
        //       name: "Products",
        //       route: "/products",
        //     },
        //     {
        //       name: "Published products",
        //       route: "/publishedProducts",
        //     },
        //   ],
        //   expanded: false,
        // },
        // {
        //   name: "Profile",
        //   icon: "far fa-fw fa-user",
        //   route: "/profile",
        //   submenu: null,
        //   expanded: false,
        // },
      ],
    };
  },
  watch: {
    $route() {
      this.closeExpandedItems();
    },
  },
  created() {
    this.getNavigation();
    this.checkRoute();
  },
  methods: {
    async getNavigation() {
      await Api.get("user/").then((response) => {
        this.user = response.data;
      });

      if (this.user.role.id === "1889B2E8093") {
        //ADMIN
        this.navigationItems = [
          //   {
          //     name: "Home",
          //     icon: "far fa-fw fa-home",
          //     route: "/",
          //     submenu: null,
          //     expanded: false,
          //   },
          {
            name: "Company",
            icon: "far fa-fw fa-building",
            submenu: [
              {
                name: "Companies",
                route: "/companies",
              },
              {
                name: "Admins",
                route: "/admins",
              },
            ],
            expanded: false,
          },
          {
            name: "Profile",
            icon: "far fa-fw fa-user",
            route: "/profile",
            submenu: null,
            expanded: false,
          },
        ];
      }else if (this.user.role.id === "18EEC46A8DB") {
        //Country Admin
        this.navigationItems = [
          //   {
          //     name: "Home",
          //     icon: "far fa-fw fa-home",
          //     route: "/",
          //     submenu: null,
          //     expanded: false,
          //   },
          {
            name: "Company",
            icon: "far fa-fw fa-building",
            submenu: [
              {
                name: "Companies",
                route: "/companies",
              },
            ],
            expanded: false,
          },
          {
            name: "Profile",
            icon: "far fa-fw fa-user",
            route: "/profile",
            submenu: null,
            expanded: false,
          },
        ];
      }  else if (this.user.role.id === "188AEA750CF") {
        //MANUFACTURER
        this.navigationItems = [
          //   {
          //     name: "Home",
          //     icon: "far fa-fw fa-home",
          //     route: "/",
          //     submenu: null,
          //     expanded: false,
          //   },
          {
            name: "Company",
            icon: "far fa-fw fa-building",
            submenu: [
              {
                name: "Company",
                route: "/company/" + this.user.company.id,
              },
              {
                name: "Employees",
                route: "/employees",
              },
            ],
            expanded: false,
          },
          {
            name: "Products",
            icon: "far fa-fw fa-basket-shopping",
            submenu: [
              {
                name: "Products",
                route: "/products",
              },
              {
                name: "Published products",
                route: "/publishedProducts",
              },
            ],
            expanded: false,
          },
          {
            name: "Profile",
            icon: "far fa-fw fa-user",
            route: "/profile",
            submenu: null,
            expanded: false,
          },
        ];
      } else if (this.user.role.id === "188AEA7CD44") {
        //PRODUCT DEVELOPER
        this.navigationItems = [
          //   {
          //     name: "Home",
          //     icon: "far fa-fw fa-home",
          //     route: "/",
          //     submenu: null,
          //     expanded: false,
          //   },
          {
            name: "Products",
            icon: "far fa-fw fa-basket-shopping",
            submenu: [
              {
                name: "Products",
                route: "/products",
              },
              {
                name: "Published products",
                route: "/publishedProducts",
              },
            ],
            expanded: false,
          },
          {
            name: "Profile",
            icon: "far fa-fw fa-user",
            route: "/profile",
            submenu: null,
            expanded: false,
          },
        ];
      } else if (this.user.role.id === "188AEA80E7A") {
        //PRODUCER
        this.navigationItems = [
          //   {
          //     name: "Home",
          //     icon: "far fa-fw fa-home",
          //     route: "/",
          //     submenu: null,
          //     expanded: false,
          //   },
          {
            name: "Products",
            icon: "far fa-fw fa-basket-shopping",
            submenu: [
              /*    {
                name: "Products",
                route: "/products",
              }, */
              {
                name: "Published products",
                route: "/publishedProducts",
              },
            ],
            expanded: false,
          },
          {
            name: "Profile",
            icon: "far fa-fw fa-user",
            route: "/profile",
            submenu: null,
            expanded: false,
          },
        ];
      }
    },
    checkRoute() {
      // Überprüfen, ob die aktuelle Route eine Übereinstimmung in den Navigationselementen hat
      let currentPath = window.location.pathname.replace("/app", "");

      const matchingNavItem = this.navigationItems.find((item) => {
        return (
          item.route === currentPath ||
          (item.submenu &&
            item.submenu.some((subItem) => subItem.route === currentPath))
        );
      });

      // Wenn eine Übereinstimmung gefunden wurde
      if (matchingNavItem) {
        // Setze den entsprechenden Hauptmenüpunkt als ausgeklappt
        this.$set(matchingNavItem, "expanded", true);

        // Wenn es ein Submenü gibt
        if (matchingNavItem.submenu) {
          // Überprüfe, ob die aktuelle Route eine Übereinstimmung im Submenü hat
          const matchingSubItem = matchingNavItem.submenu.find(
            (subItem) => subItem.route === this.currentPath
          );

          // Wenn eine Übereinstimmung im Submenü gefunden wurde
          if (matchingSubItem) {
            // Setze den entsprechenden Submenüpunkt als aktiv
            this.$set(matchingSubItem, "active", true);
          }
        }
      }
    },
    toggleSubMenu(item) {
      if (item.route) {
        this.$router.push(item.route);
      } else {
        item.expanded = !item.expanded;
      }
    },
    isActiveRoute(route) {
      return this.$route.path === route;
    },
    getItemClasses(item) {
      if (this.isSubItemActive(item)) item.expanded = true;
      return {
        active: this.isActiveRoute(item.route) || this.isSubItemActive(item),
        open: item.expanded,
      };
    },
    isSubItemActive(item) {
      if (item.submenu && item.submenu.length > 0) {
        return item.submenu.some((subItem) =>
          this.isActiveRoute(subItem.route)
        );
      }
      return false;
    },
    closeExpandedItems() {
      this.navigationItems.forEach((item) => {
        if (item.expanded && item.submenu && !this.isSubItemActive(item)) {
          item.expanded = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.navbar-side-container {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

nav {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 20px;

  .sidebar-icon {
    width: 18px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px 20px;
      cursor: pointer;
      position: relative;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #f5f5f5;
        color: #5d8ef5;
      }

      span {
        display: flex;
        align-items: center;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 10px 0 0 20px;

        li::before {
          content: "\25E6"; /* Nicht ausgefüllter Kreis */
          font-size: 27px;
        }

        li {
          padding: 8px 0;
          transition: background-color 0.3s ease;
          display: flex;
          align-items: center;
          height: 45px;

          &:hover {
            background-color: #f5f5f5;
          }

          &.active span {
            color: #5d8ef5;
          }

          &.active::before {
            content: "\25CF"; /* Ausgefüllter Kreis */
            font-size: 16px;
          }

          span {
            font-size: 14px;
          }
        }
      }
    }

    .active {
      background-color: #f5f5f5;
      color: #5d8ef5;
    }
  }
}
</style>
