<template>
  <div>
    <div class="navbar-container d-flex box-shadow-down bg-white">
      <div class="row w-100 ml-0 d-flex align-items-center">
        <div class="px-3 w-260-px">
          <div
            class="col-12 d-flex align-items-center h-68-px w-fit-content clickable"
          >
            <img
              class="w-50-px mr-2"
              src="https://carogusto-live.batix.ch/pic/18B89CD8B48.png"
              alt=""
            />
            <span>Partnerportal</span>
          </div>
        </div>
        <div
          class="navUser col-6 px-3 ml-auto d-flex align-items-center justify-content-end"
        >
          <div
            class="userIcon navUserProfile fs-28 mr-2 clickable"
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{ hovering: isHovering }"
            @click="routeToProfile"
          >
            <font-awesome-icon icon="fa-solid fa-user-circle" />
          </div>
          <div
            class="navUserProfile d-flex mx-3 clickable flex-column"
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            :class="{ hovering: isHovering }"
            @click="routeToProfile"
          >
            <span class="text-bold uppercase fs-12"
              >{{ user?.firstname }} {{ user?.lastname }}</span
            >
            <span class="fs-10">{{ user?.role?.name }}</span>
          </div>
          <div class="border-right border-dark-blue h-38-px mx-3"></div>
          <div
            class="logoutIcon fs-28 mx-3 clickable cta"
            @click="logout"
            v-tooltip.hover
            title="Logout"
          >
            <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
          </div>
        </div>
      </div>
    </div>
    <notifications class="pos-a top-68"></notifications>
  </div>
</template>
			
		<script>
import store from "@/store";
import axios from "axios";

import Api from "@/Api";

export default {
  name: "Navbar",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      isHovering: false,
      user: { firstname: null, lastname: null, role: { name: null } },
    };
  },
  store,
  computed: {
    logoutUrl() {
      if (process.env.NODE_ENV === "development") {
        return `/logout/`;
      } else {
        return `${process.env.VUE_APP_SERVER_URL}/logout/`;
      }
    },
  },
  created() {
    this.getUser();
  },
  watch: {},
  destroyed() {},
  methods: {
    getUser() {
      Api.get("user/").then((response) => {
        this.user = response.data;
      });
    },
    async logout() {
      try {
        await axios.post(`${this.logoutUrl}`, null);
        let loginSeite = this.$router.resolve({ name: "login" });
        window.location.href = loginSeite.href;
      } catch (error) {
        console.log(error);
      }
    },
    routeToProfile() {
      this.$router.push({
        name: "profile",
      });
    },
  },
};
</script>
			
			<style lang="scss">
</style>
			