import Vue from "vue";
import Vuex from "vuex";

import "../extensions/ArrayExtension";
import selectionStore from './SelectionStore';


import { SET_USER } from "./mutations.type";
import { SET_FILTER } from "./mutations.type";
import Api from "@/Api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    listenfilter: {},
  },
  mutations: {
    [SET_USER](state, user) {
      state.user = user;
    },
    [SET_FILTER](state, val) {
      state.listenfilter = val;
    },
  },
  actions: {
    async getUser({commit}) {
      try {
        let response = await Api.get("user/");
        commit(SET_USER, response.data);
      } catch (error) {
        console.error("Failed to fetch user: ", error);
      }
    },
  },
  modules: {
    selection: selectionStore,
  },
  getters: {
    user: (state) => state.user,
    name: (state) =>
      state.user ? `${state.user.firstname} ${state.user.lastname}` : "",
  },
});
