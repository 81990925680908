/* FRAMEWORKS AND VUE-LIBRARIES start */
import Vue from "vue";
import "bootstrap/dist/js/bootstrap";

import {
    TablePlugin,
    FormCheckboxPlugin,
    FormPlugin,
    FormInputPlugin,
    DropdownPlugin,
    CollapsePlugin,
    CardPlugin,
    ButtonPlugin,
} from "bootstrap-vue";

Vue.use(TablePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormPlugin);
Vue.use(FormInputPlugin);
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
Vue.use(CardPlugin);
Vue.use(ButtonPlugin);

import vueNumeralFilterInstaller from "vue-numeral-filter";

Vue.use(vueNumeralFilterInstaller, {
    locale: "de-ch",
});

import numeral from "numeral";
import "./utils/VueFilter";

numeral.locale("de-ch");
numeral.localeData("de-ch").delimiters.thousands = "'";
numeral.localeData("de-ch").delimiters.decimal = ".";

import VueRouter from "vue-router";

Vue.use(VueRouter);

import VTooltip from "v-tooltip";

Vue.use(VTooltip);

import VueShortKey from "vue-shortkey";

Vue.use(VueShortKey);

import Notifications from "vue-notification";

Vue.use(Notifications);

import vSelect from "vue-select";

Vue.component("v-select", vSelect);

import datepicker from "./components/global/Datepicker";

Vue.component("datepicker", datepicker);

import PdfLayer from "./components/global/PdfLayer";

Vue.component("pdf-layer", PdfLayer);

import VueMeta from "vue-meta";

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});

/* EXTENSIONS AND COMPONENTS start */

/** EXTENSIONS */
import "./extensions/DateExtension";
import "./extensions/ArrayExtension";
import "./extensions/StringExtension";

/** FORMS */
import CKEditor from "@ckeditor/ckeditor5-vue2";

Vue.use(CKEditor);

/*** FORM VALIDATION */

import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, email, regex, double} from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("double", double);
extend("regex", regex);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

/* EXTENSIONS AND COMPONENTS end */

/* BASIC SETUP AND PLUGINS start */

import Permissions from "./plugins/Berechtigungen";

Vue.use(Permissions);

import EnvironmentPlugin from "./plugins/environment";
import {isMobile, isAndroid, isWinPhone, isIOS} from "mobile-device-detect";

Vue.use(EnvironmentPlugin, {
    isMobile: isMobile || isAndroid || isWinPhone || isIOS,
});

import router from "./Router";
import store from "./store";
import App from "./App";

Vue.config.productionTip = false;

if (JSON.parse(process.env.VUE_APP_DEV_TOOLS)) Vue.config.devtools = true;

Vue.prototype.$bus = new Vue(); //BUS aktivieren

/* BASIC SETUP AND PLUGINS end */

/* FONT AWESOME 6.0 start */

/** import font awesome icon component */
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

/** add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);

import {library} from "@fortawesome/fontawesome-svg-core";

import {
    faKey,
    faUser,
    faShieldCheck,
    faBuilding,
    faHome,
    faBasketShopping,
} from "@fortawesome/pro-regular-svg-icons";

import {faFileZipper, faSpinner} from "@fortawesome/pro-duotone-svg-icons";

import {
    faBan,
    faCalendar,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faCircleArrowLeft,
    faCircleArrowRight,
    faCircleUser,
    faFloppyDisk,
    faCopy,
    faPencil,
    faPlus,
    faQrcode,
    faRightFromBracket,
    faTrash,
    faUndo,
    faRotate,
    faClockRotateLeft,
    faGaugeHigh,
    faGlassWaterDroplet,
    faPause,
    faHashtag,
    faClock,
    faDroplet
} from "@fortawesome/pro-solid-svg-icons";

import {faTimes, faEye, faEyeSlash} from "@fortawesome/pro-light-svg-icons";

library.add(
    faCopy,
    faDroplet,
    faClock,
    faHashtag,
    faPause,
    faGlassWaterDroplet,
    faGaugeHigh,
    faClockRotateLeft,
    faRotate,
    faQrcode,
    faCheckCircle,
    faFloppyDisk,
    faChevronRight,
    faChevronLeft,
    faBan,
    faTrash,
    faPlus,
    faBasketShopping,
    faPencil,
    faRightFromBracket,
    faHome,
    faBuilding,
    faCircleUser,
    faShieldCheck,
    faEyeSlash,
    faEye,
    faKey,
    faUser,
    faFileZipper,
    faBan,
    faCircleArrowRight,
    faCircleArrowLeft,
    faUndo,
    faSpinner,
    faTimes,
    faCalendar
);

/* FONT AWESOME 6.0 end */


/* INITIALIZE APP */

new Vue({
    render: (h) => h(App),
    store,
    router,
}).$mount("#carogusto-app");
