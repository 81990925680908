<template>
  <div id="app">
    <!-- <router-view v-if="user || loginError"></router-view> -->
    <navbar v-if="showNavbar" />
    <div class="container-fluid">
      <div class="row">
        <navbar-side v-if="showNavbar" />
        <router-view
          class="col flex-grow-1"
          @setNavbarVisibility="setNavbarVisibility"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { SET_USER } from "@/store/mutations.type";

import Navbar from "@/components/global/Navbar";
import NavbarSide from "@/components/global/NavbarSide";

import Api from "@/Api";

export default {
  name: "App",
  components: { Navbar, NavbarSide },
  data() {
    return {
      loginError: false,
      showNavbar: null,
    };
  },
  store,
  watch: {},
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit(SET_USER, value);
      },
    },
    // showNavbar() {
    //   if (window.location.pathname.includes("login")) return false;
    //   return true;
    // },
  },
  async created() {
    if (!this.user && !window.location.pathname.includes("login") && !window.location.pathname.includes("newpassword"))
      try {
        let response = await Api.get("user/");
        this.user = response.data;
      } catch (e) {
        this.loginError = true;
      }

    if (this.showNavbar !== false) this.showNavbar = true;
  },
  mounted() {},
  methods: {
    // updateNavbarVisibility() {
    //   this.showNavbar = !window.location.pathname.includes("login");
    // },
    setNavbarVisibility(value) {
      this.showNavbar = value;
    },
  },
};
</script>

<style lang="scss">
@import "./scss/app.scss";
</style>
